import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'funding',
		pluralName: 'fundings',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		state: {
			processingDonations: [],
			failedToProcessDonations: [],
			processingDonationsTotal: 0
		},
		actions: {
			async refundDonations({ dispatch, commit }, { fundingId }) {
				commit('SET_PROCESSING_DONATIONS_TOTAL', 0);

				return dispatch('request', { method: 'POST', path: `fundings/${fundingId}/donations/refund` }).then(
					r => r.data
				);
			},
			async completeDonations({ dispatch, commit }, { fundingId }) {
				commit('SET_PROCESSING_DONATIONS_TOTAL', 0);

				return dispatch('request', { method: 'POST', path: `fundings/${fundingId}/donations/complete` }).then(
					r => r.data
				);
			},
			async getDonationCounts({ dispatch, commit }, { fundingId }) {
				return dispatch('request', { method: 'GET', path: `fundings/${fundingId}/donations/count` }).then(
					r => r.data
				);
			}
		},
		mutations: {
			SET_PROCESSING_DONATIONS(state, value) {
				state.processingDonations = value;
			},
			SET_PROCESSING_DONATIONS_TOTAL(state, value) {
				state.processingDonationsTotal = value;
			},
			SET_FAILED_TO_PROCESS_DONATIONS(state, value) {
				state.failedToProcessDonations = value;
			},
			RESET_DONATIONS_PROCESSING(state) {
				state.processingDonations = [];
				state.failedToProcessDonations = [];
				state.processingDonationsTotal = 0;
			}
		}
	});
}
