import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'donation',
		pluralName: 'donations',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		actions: {
			async refundDonation({ dispatch }, { donationId }) {
				return dispatch('request', { method: 'POST', path: `donations/${donationId}/refund` }).then(r => r.data);
			}
		}
	});
}
