import { mapState, mapMutations, mapGetters } from 'vuex';
import { EventTypes } from '../lib/constants';
import withPusher from './withPusher';
import config from '@/config';

export default {
	mixins: [withPusher],
	computed: {
		...mapState('funding', ['processingDonations', 'failedToProcessDonations', 'processingDonationsTotal']),
		donationProcessChannelName() {
			return `account.${config.hpStoreAccountID}.donations.complete`;
		}
	},
	mounted() {
		if (!config.hpStoreAccountID) return;
		this.initializePusherSubscribers();
	},
	beforeDestroy() {
		if (!this.pusherClient) return;
		this.pusherClient.unsubscribe(this.donationProcessChannelName);
	},
	methods: {
		...mapMutations({
			SET_PROCESSING_DONATIONS: 'funding/SET_PROCESSING_DONATIONS',
			SET_FAILED_TO_PROCESS_DONATIONS: 'funding/SET_FAILED_TO_PROCESS_DONATIONS',
			SET_PROCESSING_DONATIONS_TOTAL: 'funding/SET_PROCESSING_DONATIONS_TOTAL'
		}),
		donationProcessedHandler(message) {
			const releasedDonationId = _.get(message, 'data.donation.donationId');
			// const releasedDonationObjectId = _.get(message, 'data.donation.id');
			this.SET_PROCESSING_DONATIONS_TOTAL(this.processingDonationsTotal + 1);
			/*
					Prevent showing more than 1 donation notification.
					It cleans all notifications before showing new one, as ofs-oneflow-layout does not support notification groups
			*/
			this.$notify({ clean: true });
			this.$notify({
				type: 'success',
				title: 'Success',
				text: `Donation #${releasedDonationId} has been processed`
			});
		},
		donationProcessingFailedHandler(message) {
			//const failedToProcessDonationObjectId = _.get(message, 'data.details.id');
			const failedToProcessDonationId = _.get(message, 'data.details.donationId');
			const text = _.get(
				message,
				'data.error.message',
				this.$t(`Error while processing a donation ${failedToProcessDonationId}`)
			);
			this.SET_PROCESSING_DONATIONS_TOTAL(this.processingDonationsTotal + 1);
			/*
				Prevent showing more than 1 donation processing notification.
				It cleans all notifications before showing new one, as ofs-oneflow-layout does not support notification groups
			*/
			this.$notify({ clean: true });
			this.$notify({
				type: 'error',
				title: this.$t('Error'),
				text
			});
		},
		initializePusherSubscribers() {
			try {
				const existingChannels = this.pusherClient.getChannels();
				const existingDonationProcessingChannel = _.find(existingChannels, ['name', this.donationProcessChannelName]);
				if (existingDonationProcessingChannel) return;
				this.pusherClient.subscribe(
					this.donationProcessChannelName,
					EventTypes.DonationProcessed,
					this.donationProcessedHandler
				);
				this.pusherClient.subscribe(
					this.donationProcessChannelName,
					EventTypes.DonationProcessingFailed,
					this.donationProcessingFailedHandler
				);
			} catch (error) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('Error while initializing donation processing pusher subscribers')
				});
			}
		}
	}
};
