import OrdersList from './Orders/OrdersList';
import PreOrdersList from './PreOrders/PreOrderList';
import OrderDetails from './Orders/OrderDetails';
import PayoutCodesList from './PayoutCodes/PayoutCodesList';
import PayoutCodeEdit from './PayoutCodes/PayoutCodeEdit';
import CouponsList from './Coupons/CouponsList';
import CouponsEdit from './Coupons/CouponEdit';
import DonationsList from './Donations/DonationsList';
import DonationDetails from './Donations/DonationDetails';

const routes = [
	{
		path: '/',
		name: 'sell',
		redirect: '/orders'
	},
	{
		name: 'orders.list',
		path: '/orders',
		component: OrdersList
	},
	{
		name: 'pre-orders.list',
		path: '/pre-orders',
		component: PreOrdersList
	},
	{
		name: 'orders.details',
		path: '/orders/:id',
		component: OrderDetails
	},
	{
		name: 'payout-codes.list',
		path: '/payout-codes',
		component: PayoutCodesList
	},
	{
		name: 'payout-codes.edit',
		path: '/payout-codes/:id',
		component: PayoutCodeEdit
	},
	{
		name: 'coupons.list',
		path: '/coupons',
		component: CouponsList
	},
	{
		name: 'coupons.edit',
		path: '/coupons/:id',
		component: CouponsEdit
	},
	{
		name: 'funding.donations',
		path: '/funding/:id/donations',
		component: DonationsList
	},
	{
		name: 'funding.donation',
		path: '/funding/:fundingId/donations/:donationId',
		component: DonationDetails
	}
];

export default routes;
