<template>
	<div>
		<OfsPanel>
			<div class="row mb-4">
				<div class="col-sm-12 col-md-6">
					<h1>{{ $t('Donation Details') }} #{{ donation.donationId }}</h1>
				</div>
				<div class="col-sm-12 col-md-6 text-md-right">
					<b-dropdown variant="primary" :text="$t('Actions')" right>
						<b-dropdown-item :disabled="!isRefundPossible" @click="showRefundModal()">
							<icon name="times-circle" class="mr-2 mt-1 mb-1 dropdown_icon" />
							{{ $t('Refund') }}
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<h2>{{ $t('Product') }}</h2>
					<b-card class="mb-2" header-class="p-2 bg-white">
						<div class="row">
							<div class="col-md-1">
								<Thumbnail :src="_get(donation, 'previewUrl')" size="46" />
							</div>
							<div class="col-md-8 d-flex align-items-center">
								<router-link
									:to="buildRouterLink('products.edit', _get(donation, 'donationOption.product._id'))"
									class="font-weight-bold text-dark"
								>
									{{ _get(donation, 'donationOption.product.name') }}
								</router-link>
							</div>
							<div class="col-md-3 d-flex align-items-center">
								<OfsBadge :text="donationRewardLabel" />
							</div>
						</div>
					</b-card>
				</div>
				<div class="col-md-5 offset-xl-1">
					<b-row class="donation-details__section-title">
						<h2>
							{{ $t('Donation Details') }}
						</h2>
					</b-row>
					<div class="row">
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Submitted') }}</div>
							<div class="DetailsField-value">
								{{ donation.createdAt ? formatDate(donation.createdAt) : '' }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Store Region') }}</div>
							<div class="DetailsField-value">
								{{ donation.storeRegion }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Status') }}</div>
							<div class="DetailsField-value">
								<OfsBadge v-if="donation.status" :text="donation.status" :status="donation.status" />
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Payment data') }}</div>
							<div class="DetailsField-value">
								<b-btn v-b-modal.modal-payment-data variant="link" class="p-0">
									{{ $t('Click to open') }}
								</b-btn>

								<b-modal
									id="modal-payment-data"
									:title="$t('Payment data')"
									:ok-title="$t('Close')"
									ok-only
									ok-variant="secondary"
									size="xl"
								>
									<CodeEditor :value="JSON.stringify(_get(donation, 'payment'), null, 2)" />
								</b-modal>
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Donated Amount') }}</div>
							<div class="DetailsField-value">
								{{ formatCents(donation.transactionValue, donation.transactionCurrency, '-') }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Name') }}</div>
							<div class="DetailsField-value">
								{{ donation.donorName }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Email') }}</div>
							<div class="DetailsField-value">
								{{ donation.donorEmail }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Contributor Name') }}</div>
							<div class="DetailsField-value">
								{{ donation.contributorName || '-' }}
							</div>
						</div>
					</div>
					<b-row v-if="donation.shipping" class="donation-details__section-title mt-4">
						<h2>
							{{ $t('Shipping Details') }}
						</h2>
					</b-row>
					<div v-if="donation.shipping" class="row">
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Address') }}</div>
							<div class="DetailsField-value">
								{{ donation.shipping.address1 }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Address2') }}</div>
							<div class="DetailsField-value">
								{{ donation.shipping.address2 }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Postcode') }}</div>
							<div class="DetailsField-value">
								{{ donation.shipping.postcode }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('State') }}</div>
							<div class="DetailsField-value">
								{{ donation.shipping.state }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('City') }}</div>
							<div class="DetailsField-value">
								{{ donation.shipping.city }}
							</div>
						</div>
						<div class="DetailsField col-md-12 d-flex justify-content-between">
							<div class="DetailsField-label">{{ $t('Country') }}</div>
							<div class="DetailsField-value">
								{{ donation.shipping.country }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</OfsPanel>

		<b-modal
			:visible="isRefundModalVisible"
			:title="$t('Refund donation')"
			:cancel-title="$t('No')"
			:ok-title="$t('Yes')"
			ok-variant="danger"
			size="md"
			modal-class="RefundDonationModal"
			@hide="isRefundModalVisible = false"
			@ok="() => onRefundDonation(donation._id)"
		>
			<span>
				{{ $t('Are you sure you want to refund donation?') }}
			</span>
		</b-modal>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsBadge, OfsPanel } from '@oneflow/ofs-vue-layout';
import CodeEditor from '@/components/CodeEditor';
import Thumbnail from '@/components/Thumbnail';
import formatDate from '../../../lib/formatDate';
import formatCents from '../../../lib/formatCents';
import { donationRewardLabels, donationStatus } from '@/lib/constants';

export default {
	components: {
		OfsBadge,
		CodeEditor,
		OfsPanel,
		Thumbnail
	},
	data() {
		return {
			isRefundModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			donation: 'donation/donation'
		}),
		donationRewardLabel() {
			const reward = _.get(this.donation, 'donationOption.reward');

			return donationRewardLabels[reward] || 'NO REWARD';
		},
		isRefundPossible() {
			return _.get(this.donation, 'status', null) !== donationStatus.Refunded;
		}
	},
	watch: {
		'$route.params.donationId': {
			immediate: true,
			async handler(donationId) {
				await this.initialize(donationId);
			}
		}
	},
	methods: {
		...mapActions({
			findById: 'donation/findById',
			refundDonation: 'donation/refundDonation'
		}),
		_get: _.get,
		formatDate,
		formatCents,
		async findDonationById(donationId) {
			return this.findById({
				id: donationId,
				query: {
					query: {
						$populate: [
							{
								path: 'donationOption',
								$populate: [{ path: 'product', $select: { _id: 1, name: 1 } }]
							}
						]
					}
				}
			});
		},
		buildRouterLink(path, id) {
			return {
				name: path,
				params: { id }
			};
		},
		showRefundModal() {
			this.isRefundModalVisible = true;
		},
		async onRefundDonation(donationId) {
			try {
				await this.refundDonation({
					donationId
				});
				await this.findDonationById(donationId);
				this.$notify({ type: 'success', text: this.$t('Donation refunded successfully') });
			} catch (err) {
				this.$notify({ type: 'error', text: err.message });
			}
		},
		async initialize(donationId) {
			return this.findDonationById(donationId);
		}
	}
};
</script>

<style lang="scss">
@import '../../../styles/shared';

.DetailsField {
	margin: 6px 0 6px 0;

	&-label {
		font-size: 14px;
		color: #8a9bb4;
	}

	&-value {
		@include ofTextStyleFormValues();
	}
}

.donation-details__section-title {
	background-color: #f2f6fe;
	display: flex;
	padding: 10.5px;
	margin-bottom: 16px;

	h2 {
		font-size: 15px;
		margin: 0;
	}
}
</style>
