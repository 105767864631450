var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OfsPanel',[_c('ListTable',{attrs:{"bordered":"","hover":"","table-title":"Donations","config":_vm.config,"items":_vm.donations.data,"fields":_vm.fields,"filter":_vm.filter,"sort":_vm.sort,"selected":_vm.selected,"total-items":_vm.donations.total,"current-page":_vm.currentPage,"per-page":_vm.perPage,"fetch-data":_vm.fetchData,"is-busy":_vm.isLoading},on:{"table-change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"TableButtons-Slot-left",fn:function(ref){return [_c('OfFormInput',{staticClass:"col-6 mr-2",attrs:{"value":_vm.searchString,"placeholder":_vm.$t('Search by donation ID or name')},on:{"input":_vm.handleSearchStringInput}}),_c('OfInlineFilter',{attrs:{"filters":_vm.filters,"values":_vm.filterValues},on:{"change":_vm.filtersChanged}})]}},{key:"TableHeader",fn:function(ref){return [_c('OfFilterBar',{attrs:{"filters":_vm.filters,"values":_vm.filterValues},on:{"change":_vm.filtersChanged}})]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('OfsBadge',{attrs:{"text":item.status,"status":_vm.getItemStatus(item.status)}}):_vm._e()]}},{key:"cell(transactionValue)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDonatedAmount(item))+" ")]}},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{attrs:{"size":"sm","href":("/#/funding/" + (item.fundingId) + "/donations/" + (item._id))}},[_vm._v(_vm._s(_vm.$t('View')))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }