/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import { $t } from '../../../vuex';

export const productAvailability = {
	PreOrder: 'pre-order',
	Available: 'available',
	Unavailable: 'unavailable',
	Funding: 'funding'
};

export const productStatus = {
	Inactive: 'inactive',
	PendingApproval: 'pending approval',
	Approved: 'approved',
	Published: 'published'
};

export const ProductSharing = {
	readonly: 'readonly'
};

export const orderPaymentStatus = {
	Pending: 'pending',
	Paid: 'paid',
	Failed: 'failed',
	Cancelled: 'cancelled',
	Error: 'error',
	Refunded: 'refunded'
};

export const productStatusOptions = [
	{ text: $t('Inactive'), value: productStatus.Inactive },
	{ text: $t('Pending approval'), value: productStatus.PendingApproval },
	{ text: $t('Approved'), value: productStatus.Approved },
	{ text: $t('Published'), value: productStatus.Published }
];

export const productAvailabilityOptions = [
	{ text: $t('Pre-order'), value: productAvailability.PreOrder },
	{ text: $t('Available'), value: productAvailability.Available },
	{ text: $t('Unavailable'), value: productAvailability.Unavailable },
	{ text: $t('Funding'), value: productAvailability.Funding }
];

export const orderPaymentStatusOptions = [
	{ text: $t('Pending'), value: orderPaymentStatus.Pending },
	{ text: $t('Paid'), value: orderPaymentStatus.Paid },
	{ text: $t('Failed'), value: orderPaymentStatus.Failed },
	{ text: $t('Cancelled'), value: orderPaymentStatus.Cancelled },
	{ text: $t('Error'), value: orderPaymentStatus.Error },
	{ text: $t('Refunded'), value: orderPaymentStatus.Refunded }
];

export const productFormats = {
	DIGITAL: 'digital',
	PHYSICAL: 'physical'
};

export const defaultProductData = {
	type: 'standard',
	status: productStatus.Inactive,
	availability: productAvailability.Unavailable,
	attributes: {},
	previewImages: [],
	format: productFormats.PHYSICAL,
	formats: []
};

export const defaultContentComponent = {
	name: 'Content',
	type: 'content',
	componentCode: 'text',
	spine: {
		size: 0
	},
	sizes: {
		media: {
			width: 816,
			height: 816
		},
		bleed: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0
		},
		trim: {
			top: 11,
			right: 11,
			bottom: 11,
			left: 11
		}
	},
	totalWeight: null,
	pageWeight: 3.4019424
};

export const productFormatFulfilment = {
	print: 'SiteFlow',
	rpi: 'RPI UOS',
	digital: 'Digital File',
	shipStation: 'ShipStation'
};

export const productFormatAvailability = {
	available: 'available',
	unavailable: 'unavailable'
};

export const formatAvailabilityOptions = [
	{ text: $t('Available'), value: productFormatAvailability.available },
	{ text: $t('Unavailable'), value: productFormatAvailability.unavailable }
];

export const defaultFormatData = {
	name: '',
	fulfilment: productFormatFulfilment.print,
	prices: [],
	availability: productFormatAvailability.unavailable
};

export const defaultLicense = {
	pageText: `This pdf-book was sold to {{ $order.customer.name }} {{ $order.customer.address1 }} on {{ moment $order.createdAt "YYYY-MM-DD" }}. Order number: {{ $order.orderId }}\n\n This book is subject to the terms of this license. You own this file once you’ve downloaded it, and you can use it on any of your devices. It has visible watermarks that contain your email. You are prohibited to upload this book to any website or file sharing network, or in any other way making it available for distribution, sharing, copying, downloading or reselling. Royalties from every sale will be paid to the author: if you’re reading someone else’s copy, please buy your own license of the book @hp_custom_content`
};

export const defaultFundingData = {
	targetAmount: 0,
	donatedAmount: 0,
	baseCurrency: 'USD',
	donationOptionIds: [],
	donationOptions: []
};
