<template>
	<div>
		<OfsPanel>
			<ListTable
				bordered
				hover
				table-title="Donations"
				:config="config"
				:items="donations.data"
				:fields="fields"
				:filter="filter"
				:sort="sort"
				:selected="selected"
				:total-items="donations.total"
				:current-page="currentPage"
				:per-page="perPage"
				:fetch-data="fetchData"
				:is-busy="isLoading"
				@table-change="handleTableChange"
			>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<OfFormInput
						:value="searchString"
						class="col-6 mr-2"
						:placeholder="$t('Search by donation ID or name')"
						@input="handleSearchStringInput"
					/>
					<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>
				<template slot="TableHeader" slot-scope="{}">
					<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>

				<template #cell(status)="{ item }">
					<OfsBadge v-if="item.status" :text="item.status" :status="getItemStatus(item.status)" />
				</template>

				<template #cell(transactionValue)="{ item }">
					{{ getDonatedAmount(item) }}
				</template>

				<template #cell(createdAt)="{ item }">
					{{ formatDate(item.createdAt) }}
				</template>

				<template #cell(actions)="{ item }">
					<b-button size="sm" :href="`/#/funding/${item.fundingId}/donations/${item._id}`">{{ $t('View') }}</b-button>
				</template>
			</ListTable>
		</OfsPanel>
	</div>
</template>

<script>
import _ from 'lodash';
import {
	OfsPanel,
	ListTable,
	OfInlineFilter,
	OfFilterBar,
	OfsBadge,
	OfFormCheckbox,
	OfFormInput
} from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import formatDate from '@/lib/formatDate';
import formatCents from '@/lib/formatCents';
import { donationStatus, donationStatusOptions } from '@/lib/constants';
import { countryOptions } from '@/lib/country';
import withQuerystringState from '@/mixins/withQuerystringState';
import { parseDefaultQuerystringFilterValues } from '@/lib/parseDefaultQuerystringFilterValues';

const config = {
	refresh: { visible: true },
	columns: { visible: true }
};

export default {
	components: {
		OfsPanel,
		ListTable,
		OfInlineFilter,
		OfFilterBar,
		OfsBadge,
		OfFormInput
	},
	mixins: [
		withQuerystringState([
			{ name: 'currentPage', defaultValue: 1, parseValue: Number },
			{ name: 'perPage', defaultValue: 10, parseValue: Number },
			'searchString',
			{ name: 'sort', defaultValue: {}, parseValue: value => value ?? {} },
			{ name: 'filterValues', parseValue: parseDefaultQuerystringFilterValues }
		])
	],
	data() {
		const fields = [
			{ key: 'donationId', label: this.$t('Donation ID') },
			{ key: 'status', label: this.$t('Status') },
			{
				key: 'donorName',
				label: this.$t('Donor Name'),
				sortable: true
			},
			{
				key: 'donorEmail',
				label: this.$t('Donor Email'),
				sortable: true
			},
			{ key: 'transactionValue', label: this.$t('Amount Donated') },
			{ key: 'createdAt', label: this.$t('Date'), sortable: true },
			{ key: 'actions', label: this.$t('Actions'), sortable: false }
		];

		return {
			isLoading: false,
			fields,
			selected: _.map(fields, 'key'),
			selectedItems: [],
			selectedItem: null,
			config,
			filter: true,
			donationStatus
		};
	},
	computed: {
		...mapGetters({
			donations: 'donation/donations'
		}),
		fundingId() {
			const { id } = this.$route.params;

			return id;
		},
		filters() {
			return [
				{
					header: this.$t('Status'),
					key: 'status',
					type: 'checkbox',
					items: _.map(donationStatusOptions, ({ text, value }) => ({ title: text, value }))
				},
				{
					header: this.$t('Region'),
					key: 'storeRegion',
					type: 'checkbox',
					items: countryOptions.map(el => ({ ...el, title: el.text }))
				}
			];
		}
	},
	async mounted() {
		await Promise.all([this.fetchData()]);
	},
	methods: {
		...mapActions({
			getDonations: 'donation/find'
		}),
		formatDate,
		formatCents,
		_get: _.get,
		_sumBy: _.sumBy,
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$where: {
						$and: [{ status: { $nin: [donationStatus.Pending, donationStatus.Abandoned] } }, { fundingId: this.fundingId }]
					}
				};

				if (!_.isEmpty(this.filterValues)) {
					query.$where = _.reduce(
						this.filterValues,
						(filterQuery, value, key) => {
							if (key !== 'productIds') {
								filterQuery.$and.push({ [key]: Array.isArray(value) ? { $in: value } : value });
							}

							return filterQuery;
						},
						{ $and: [...(query.$where.$and ?? [])] }
					);
				}

				if (this.searchString) {
					query.$where.$or = [{ donorName: { $regex: this.searchString, $options: 'i' } }];

					const isNumber = !isNaN(this.searchString);
					if (isNumber) {
						query.$where.$or.push({ donationId: Number(this.searchString) });
					}
				}

				query.$sort = !_.isEmpty(this.sort) ? this.sort : { createdAt: -1 };

				await this.getDonations({ query: { query } });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching donations')
				});

				throw err;
			} finally {
				this.isLoading = false;
			}
		},
		handleTableChange({ currentPage, perPage, sort, filter, selectedCols }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.selected = selectedCols;
			if (filter !== undefined) {
				this.filter = filter;
			}
			if (sort) {
				this.sort = sort;
			}
		},
		filtersChanged(filters) {
			this.filterValues = filters;
			this.currentPage = 1;
			this.fetchData();
		},
		getDonatedAmount(donation) {
			const transactionValue = _.get(donation, 'transactionValue');
			const isoCurrency = _.get(donation, 'transactionCurrency');
			return formatCents(transactionValue, isoCurrency) || 0;
		},
		handleSearchStringInput: _.debounce(function(value) {
			this.searchString = value;
			this.currentPage = 1;
			this.fetchData();
		}, 800),
		getItemStatus(status) {
			switch (status) {
				case donationStatus.Paid: {
					return 'Paid';
				}
				case donationStatus.Refunded: {
					return 'Refunded';
				}
			}
		}
	}
};
</script>
