<template>
	<div>
		<b-card>
			<b-row>
				<b-col>
					<h5>{{ $t('Funding Configuration') }}</h5>
					<div v-if="!isEditingFunding">
						<p>
							<strong>{{ $t('Target Amount (cents)') }}:</strong>
							{{ formatCents(formData.targetAmount, formData.baseCurrency) || 0 }}
						</p>
						<p>
							<strong>{{ $t('Base Currency') }}:</strong> {{ formData.baseCurrency }}
						</p>
						<b-row>
							<b-col md="2">
								<p>
									<strong>{{ $t('Donated Amount') }}:</strong
									>{{ formatCents(formData.donatedAmount, formData.baseCurrency) || 0 }}
								</p>
							</b-col>
						</b-row>
						<p>
							<strong>{{ $t('Closing Date') }}:</strong>
							{{ formData.closingDate ? formatDate(formData.closingDate) : null }}
						</p>
						<b-button class="mr-2" variant="primary" @click="editFunding">{{ $t('Configure') }}</b-button>
					</div>
					<b-col v-else md="3">
						<OfFormInput :label="$t('Target Amount (cents)')" type="number" name="targetAmount" show-errors required />
						<OfFormSelect
							:label="$t('Base Currency')"
							name="baseCurrency"
							:options="currencyOptions"
							show-errors
							required
						/>
						<OfFormInput type="date" :label="$t('Closing Date')" name="closingDate" show-errors required />
						<b-button class="mr-3" variant="primary" @click="saveFunding">{{ $t('Save') }}</b-button>
						<b-button @click="cancelFundingEdit">{{ $t('Cancel') }}</b-button>
					</b-col>

					<div class="d-flex mt-3">
						<p class="mr-5">
							<strong>{{ $t('Open donations: ') }}:</strong>
							{{ openDonationsCount }}
						</p>
						<p class="mr-5">
							<strong>{{ $t('Finalised donations: ') }}:</strong>
							{{ finalizedDonationsCount }}
						</p>

						<a :href="`/#/funding/${formData._id}/donations`" target="_blank">{{ $t('View All') }}</a>
					</div>

					<div v-if="!isEditingFunding">
							<b-button
							class="mr-3"
								variant="primary"
								:disabled="openDonationsCount === 0"
								@click="showDonationProcessingModal('complete')"
								>{{ $t('Complete donations') }}</b-button
							>

							<b-button
								variant="danger"
								:disabled="openDonationsCount === 0"
								@click="showDonationProcessingModal('refund')"
								>{{ $t('Refund donations') }}</b-button
							>
					</div>

				</b-col>
			</b-row>
		</b-card>

		<b-card class="mt-4">
			<b-row>
				<b-col>
					<h5>{{ $t('Donation Options') }}</h5>
					<template v-if="fundingExists">
						<b-table :items="formData.donationOptions" :fields="donationOptionFields" responsive small class="mb-3">
							<template #cell(title)="data">
								<template v-if="data.item.editing">
									<OfFormInput
										:name="`donationOptions[${data.index}].title`"
										:label="$t('Title')"
										show-errors
										required
									/>
								</template>
								<template v-else>
									{{ data.item.title }}
								</template>
							</template>

							<template #cell(description)="data">
								<template v-if="data.item.editing">
									<OfFormInput
										:name="`donationOptions[${data.index}].description`"
										:label="$t('Description')"
										show-errors
										required
									/>
								</template>
								<template v-else>
									{{ data.item.description }}
								</template>
							</template>

							<template #cell(reward)="data">
								<template v-if="data.item.editing">
									<OfFormSelect
										:name="`donationOptions[${data.index}].reward`"
										:label="$t('Reward')"
										:options="rewardOptions"
									/>
								</template>
								<template v-else>
									{{ getRewardText(data.item.reward) }}
								</template>
							</template>

							<template #cell(formatId)="data">
								<template
									v-if="
										data.item.editing && (data.item.reward === 'firstEdition' || data.item.reward === 'contributor')
									"
								>
									<OfFormSelect
										:name="`donationOptions[${data.index}].formatId`"
										:label="$t('Format')"
										:options="productFormatOptions"
									/>
								</template>
								<template
									v-if="
										!data.item.editing && (data.item.reward === 'firstEdition' || data.item.reward === 'contributor')
									"
								>
									{{ getFormatName(data.item.formatId) }}
								</template>
							</template>

							<template #cell(prices)="data">
								<template v-if="data.item.editing">
									<b-row v-for="(price, priceIndex) in data.item.prices" :key="priceIndex" class="pt-4">
										<b-col md="6 pt-1">
											<OfFormInput
												:label="`${$t('Price')} (cents)`"
												:name="`donationOptions[${data.index}].prices[${priceIndex}].amount`"
												show-errors
											>
												<template v-slot:prepend>
													<b-input-group-text>
														{{ getCurrencySymbol(formData.donationOptions[data.index].prices[priceIndex].currency) }}
													</b-input-group-text>
												</template>
												<template v-slot:append>
													<OfFormSelect
														:name="`donationOptions[${data.index}].prices[${priceIndex}].currency`"
														:options="currencyOptions"
														class="h-100"
														without-label
													/>
												</template>
											</OfFormInput>
										</b-col>
										<b-col md="2" class="d-flex align-items-center pt-2">
											<b-button variant="danger" @click="deletePrice(data.index, priceIndex)">
												{{ $t('Remove') }}
											</b-button>
										</b-col>
									</b-row>
									<b-button variant="outline-primary" @click="addPrice(data.index)">
										{{ $t('Add Price') }}
									</b-button>
								</template>
								<template v-else>
									<div v-for="price in data.item.prices" :key="price.currency">
										{{ formatCents(price.amount, price.currency) }}
									</div>
								</template>
							</template>

							<template #cell(actions)="data">
								<template v-if="data.item.editing">
									<div class="d-flex flex-column align-items-center justify-content-center">
										<b-button class="w-100 mb-2" variant="primary" @click="saveDonationOption(data.item, data.index)">
											{{ $t('Save') }}
										</b-button>
										<b-button variant="secondary" class="w-100" @click="cancelEdit(data.item)">
											{{ $t('Cancel') }}
										</b-button>
									</div>
								</template>
								<template v-if="!isDonationOptionEditing">
									<b-button variant="primary" @click="editDonationOption(data.index)">
										{{ $t('Edit') }}
									</b-button>
									<b-button class="ml-3" variant="danger" @click="deleteDonationOption(data.item.id, data.index)">
										{{ $t('Remove') }}
									</b-button>
								</template>
							</template>
						</b-table>
						<b-button variant="primary" :disabled="disableAddOption" @click="addDonationOption">
							{{ $t('Add Donation Option') }}
						</b-button>
					</template>
					<template v-else>
						<p>{{ $t('Please configure funding before adding donation options.') }}</p>
					</template>
				</b-col>
			</b-row>
		</b-card>

		<b-modal
			:visible="isDonationProcessingModalVisible"
			:title="$t('Process donations')"
			:cancel-title="$t('No')"
			:ok-title="$t('Yes')"
			ok-variant="primary"
			size="md"
			modal-class="ProcessDonationsModal"
			@hide="hideDonationProcessingModal"
		>
			<b-col>
				<template v-if="donationProcessingFinished">
					<b-alert :show="true" variant="success">
						<span>{{ $t('Processed') }} {{ openDonationsCount }} / {{ openDonationsCount }}</span>
					</b-alert>
				</template>
				<template v-else>
					<p v-if="canProcessDonations">{{ $t(`Are you sure you want to ${donationProcessingType} all donations?`) }}</p>
					<p v-else>{{ $t('Processing donations...') }}</p>
					<span>{{ processingDonationsTotal }} / {{ openDonationsCount }}</span>
					<b-progress :max="openDonationsCount" :value="processingDonationsTotal" animated />
				</template>
			</b-col>
			<div slot="modal-footer">
				<b-button v-if="canProcessDonations" class="mr-2" @click="hideDonationProcessingModal">
					{{ $t('No') }}
				</b-button>
				<b-button v-if="donationProcessingFinished" variant="primary" @click="hideDonationProcessingModal">
					{{ $t('Done') }}
				</b-button>
				<b-button v-else-if="canProcessDonations" variant="primary" @click="processDonations">
					{{ $t('Yes') }}
				</b-button>
				<b-button v-else-if="!canProcessDonations" variant="primary" :disabled="true">
					{{ $t('In progress') }}
				</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { OfFormInput, OfFormSelect, withForm, validateWithMessage } from '@oneflow/ofs-vue-layout';
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getCurrencySymbol } from '@/lib/currency';
import formatDate from '@/lib/formatDate';
import formatCents from '@/lib/formatCents';
import withCompleteDonationEvents from '@/mixins/withCompleteDonationEvents';
import { defaultFundingData } from './constants';
import { isObjectId } from '../../Create/Templates/HTMLTemplateEditor/helpers';
import { ProductFormatsFormName } from './ProductFormats';

export const FundingFormName = 'productEditFunding';

export default {
	components: {
		OfFormInput,
		OfFormSelect
	},

	mixins: [withForm(FundingFormName), withCompleteDonationEvents],

	props: {
		fundingId: {
			type: String,
			default: () => null
		},
		productId: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			isEditingFunding: false,
			isDonationProcessingModalVisible: false,
			canProcessDonations: true,
			donationProcessingType: null,
			fundingExists: !!this.fundingId,
			disableAddOption: false,
			openDonationsCount: 0,
			finalizedDonationsCount: 0,
			rewardOptions: [
				{ value: 'firstEdition', text: this.$t('First Edition') },
				{ value: 'contributor', text: this.$t('Contributor') }
			],
			currencyOptions: [
				{ value: 'USD', text: 'USD' },
				{ value: 'CAD', text: 'CAD' },
				{ value: 'GBP', text: 'GBP' },
				{ value: 'EUR', text: 'EUR' }
			],
			donationOptionFields: [
				{ key: 'title', label: this.$t('Title'), thStyle: 'width: 250px;' },
				{ key: 'description', label: this.$t('Description'), thStyle: 'width: 450px;' },
				{ key: 'reward', label: this.$t('Reward') },
				{ key: 'formatId', label: this.$t('Format') },
				{ key: 'prices', label: this.$t('Prices') },
				{ key: 'actions', label: this.$t('Actions') }
			]
		};
	},

	computed: {
		validationRules() {
			return {
				formData: {
					donationOptions: {
						required: validateWithMessage(
							this.$t('You should define at least one donation option for the funding'),
							required
						),
						$each: {
							description: {
								required: validateWithMessage(this.$t('You should define donation option description'), required)
							},
							formatId: {
								required: validateWithMessage(this.$t('Format is required'), (value, obj) => {
									return obj.reward === 'firstEdition' ? !!value : true;
								})
							},
							prices: {
								required: validateWithMessage(
									this.$t('You should define at least one price for the donation option'),
									required
								),
								$each: {
									amount: {
										required: validateWithMessage(this.$t('Donation option price amount is required'), required),
										uniqCurrency: validateWithMessage(
											this.$t('Currencies must not be duplicated'),
											(_amount, price) => {
												const formatIdx = _.findIndex(this.formData.donationOptions, opt => opt.prices.includes(price));
												const prices = _.get(this.formData.donationOptions[formatIdx], 'prices', []);
												return _.filter(prices, ['currency', price.currency]).length === 1;
											}
										)
									}
								}
							}
						}
					}
				}
			};
		},
		...mapGetters({
			getFormData: 'form/getFormData'
		}),
		productFormatsData() {
			const formatsForm = this.getFormData({ formName: ProductFormatsFormName });
			return _.get(formatsForm, 'formats', []);
		},
		productFormatOptions() {
			const defaultOption = { text: this.$t('None'), value: null };
			return [defaultOption, ...this.productFormatsData.map(format => ({ text: format.name, value: format._id }))];
		},
		closingDate() {
			if (this.formData.closingDate) {
				return new Date(this.formData.closingDate);
			}
			return new Date();
		},
		todayDate() {
			return moment().format('YYYY-MM-DD');
		},
		isDonationOptionEditing() {
			return this.formData.donationOptions.some(option => option.editing);
		},
		donationProcessingFinished() {
			return this.openDonationsCount === this.processingDonationsTotal;
		}
	},

	async mounted() {
		await this.initialize();
	},

	methods: {
		getCurrencySymbol,
		formatDate,
		formatCents,
		...mapActions({
			findFundingById: 'funding/findById',
			createFunding: 'funding/create',
			updateFunding: 'funding/update',
			createDonationOption: 'donation-option/create',
			getDonationOptions: 'donation-option/find',
			deleteDonationOptionById: 'donation-option/deleteById',
			updateDonationOption: 'donation-option/update',
			updateProductById: 'product/update',
			refundDonations: 'funding/refundDonations',
			completeDonations: 'funding/completeDonations',
			getDonationCounts: 'funding/getDonationCounts'
		}),
		...mapMutations({
			RESET_DONATIONS_PROCESSING: 'funding/RESET_DONATIONS_PROCESSING'
		}),
		editFunding() {
			this.isEditingFunding = true;
		},
		async saveFunding() {
			if (!this.fundingExists) {
				const response = await this.createFunding({
					...this.formData,
					donationOptionIds: [],
					donatedAmount: 0,
					closingDate: this.closingDate,
					productId: this.productId
				});

				await this.updateProductById({ id: this.productId, data: { fundingId: response.id } });

				this.$emit('reinitializeProduct');

				this.fundingExists = true;
			} else {
				await this.updateFunding({
					id: this.fundingId,
					data: { ..._.omit(this.formData, 'donatedAmount'), closingDate: this.closingDate }
				});
			}
			this.isEditingFunding = false;
		},
		async onCloseFunding() {
			await this.closeFunding({ fundingId: this.formData._id });

			this.$emit('reinitializeProduct');
		},
		cancelFundingEdit() {
			this.isEditingFunding = false;
			this.initialize();
		},
		addPrice(donationOptionIndex) {
			const defaultPrice = { currency: 'USD', amount: 0 };

			const donationOptionPrices = _.get(this.formData, `donationOptions[${donationOptionIndex}].prices`, []);
			this.updateField(`donationOptions[${donationOptionIndex}].prices`, [...donationOptionPrices, defaultPrice]);
		},
		deletePrice(donationOptionIndex, priceIndex) {
			const donationOptionPrices = _.get(this.formData, `donationOptions[${donationOptionIndex}].prices`, []);

			const updatedPrices = donationOptionPrices.filter((_, index) => index !== priceIndex);

			this.updateField(`donationOptions[${donationOptionIndex}].prices`, updatedPrices);
		},
		addDonationOption() {
			const defaultDonationOption = {
				id: _.uniqueId('temp_'),
				description: '',
				reward: null,
				prices: [],
				editing: true
			};
			this.updateField('donationOptions', [...this.formData.donationOptions, defaultDonationOption]);
		},
		editDonationOption(donationOptionIndex) {
			this.updateField(`donationOptions[${donationOptionIndex}].editing`, true);
			this.disableAddOption = true;
		},
		async saveDonationOption(donationOption, donationOptionIndex) {
			this.updateField(`donationOptions[${donationOptionIndex}].editing`, false);

			if (donationOption.id.startsWith('temp_')) {
				await this.saveNewDonationOption(donationOption);
			} else {
				await this.updateDonationOption({ id: donationOption.id, data: donationOption });
			}

			this.disableAddOption = false;
		},
		cancelEdit(donationOption) {
			if (donationOption.id.startsWith('temp_')) {
				this.updateField(
					'donationOptions',
					this.formData.donationOptions.filter(option => option.id !== donationOption.id)
				);
			} else {
				donationOption.editing = false;
				this.initialize();
			}

			this.disableAddOption = false;
		},
		async deleteDonationOption(donationOptionId, donationOptionIndex) {
			const filteredOptions = this.formData.donationOptions.filter(opt => opt.id !== donationOptionId);
			const filteredOptionIds = filteredOptions.map(opt => opt.id);

			this.updateField(`donationOptions[${donationOptionIndex}].editing`, false);
			this.updateField('donationOptions', filteredOptions);
			this.updateField('donationOptionIds', filteredOptionIds);

			await this.deleteDonationOptionById({ id: donationOptionId });
			await this.updateFunding({ id: this.fundingId, data: { donationOptionIds: filteredOptionIds } });

			await this.initialize();
		},
		getRewardText(reward) {
			const rewardOption = this.rewardOptions.find(option => option.value === reward);
			return rewardOption ? rewardOption.text : reward;
		},
		getFormatName(formatId) {
			if (formatId) {
				const format = this.productFormatsData.find(format => format._id === formatId);

				return format.name;
			}

			return null;
		},
		async saveNewDonationOption(donationOption) {
			const response = await this.createDonationOption({
				..._.omit(donationOption, ['id', 'editing', 'reward']),
				fundingId: this.fundingId,
				productId: this.productId,
				...(donationOption.reward ? { reward: donationOption.reward } : {})
			});

			await this.updateFunding({
				id: this.fundingId,
				data: { donationOptionIds: [...this.formData.donationOptionIds, response.id] }
			});

			await this.initialize();
		},
		async initialize() {
			let formData = { ...defaultFundingData };

			if (isObjectId(this.fundingId)) {
				formData = await this.findFundingById({
					id: this.fundingId,
					query: { query: { $populate: [{ path: 'donationOptions' }] } }
				});

				const { openDonationsCount, finalizedDonationsCount } = await this.getDonationCounts({
					fundingId: this.fundingId
				});
				this.openDonationsCount = openDonationsCount;
				this.finalizedDonationsCount = finalizedDonationsCount;

				formData.closingDate = moment(formData.closingDate).format('YYYY-MM-DD');
			}

			await this.initFormData({ ...formData });
		},
		showDonationProcessingModal(donationProcessingType) {
			this.RESET_DONATIONS_PROCESSING();
			this.isDonationProcessingModalVisible = true;
			this.donationProcessingType = donationProcessingType;
		},
		async onRefundDonations() {
			try {
				this.canProcessDonations = false;
				await this.refundDonations({ fundingId: this.fundingId });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while starting donation refund process')
				});
			}
		},
		async onCompleteDonations() {
			try {
				this.canProcessDonations = false;
				await this.completeDonations({ fundingId: this.fundingId });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while starting the process')
				});
			}
		},
		async processDonations() {
			if (this.donationProcessingType === 'refund') {
				await this.onRefundDonations();
			}
			if (this.donationProcessingType === 'complete') {
				await this.onCompleteDonations();
			}
		},
		hideDonationProcessingModal() {
			this.isDonationProcessingModalVisible = false;
			// wait until modal hide animation is finished
			setTimeout(() => {
				this.canProcessDonations = true;
			}, 500);

			this.initialize();
		}
	}
};
</script>
