import _ from 'lodash';
import { $t } from '../vuex';
import { productAvailability } from '@/containers/Create/Products/constants';

export const subdomainPattern = '^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$';

export const orderStatus = {
	Error: 'error',
	Cancelled: 'cancelled',
	Abandoned: 'abandoned',
	PreOrder: 'pre-order',
	Pending: 'pending',
	Processing: 'processing',
	Live: 'live',
	Complete: 'complete',
	Blocked: 'blocked'
};

export const orderStatusOptions = [
	{ text: $t('Error'), value: orderStatus.Error },
	{ text: $t('Pre-order'), value: orderStatus.PreOrder },
	{ text: $t('Cancelled'), value: orderStatus.Cancelled },
	{ text: $t('Processing'), value: orderStatus.Processing },
	{ text: $t('Live'), value: orderStatus.Live },
	{ text: $t('Complete'), value: orderStatus.Complete },
	{ text: $t('Blocked'), value: orderStatus.Blocked }
];

export const notificationDefaults = [
	{
		status: 'pre-order',
		subject: 'Your order has been accepted'
	},
	{
		status: 'shipped',
		subject: 'Order shipped'
	},
	{
		status: 'received',
		subject: 'Order received'
	}
];

export const intentTypes = {
	Test: 'test',
	Sample: 'sample',
	Standard: 'standard',
	Promo: 'promo'
};

export const orderIntentOptions = [
	{ text: $t('Test order'), value: intentTypes.Test },
	{ text: $t('Sample'), value: intentTypes.Sample },
	{ text: $t('Standard'), value: intentTypes.Standard },
	{ text: $t('Promo'), value: intentTypes.Promo }
];

export const socialNamesOptions = [
	{ text: $t('Instagram'), value: 'instagram' },
	{ text: $t('Facebook'), value: 'facebook' },
	{ text: $t('Pinterest'), value: 'pinterest' },
	{ text: $t('Twitter'), value: 'twitter' },
	{ text: $t('Youtube'), value: 'youtube' },
	{ text: $t('TikTok'), value: 'tiktok' }
];

export const bannerSlideType = {
	PRODUCT: 'product',
	CUSTOM: 'custom'
};

export const bannerTypeOptions = [
	{
		text: $t('Custom'),
		value: bannerSlideType.CUSTOM
	},
	{
		text: $t('Product'),
		value: bannerSlideType.PRODUCT
	}
];

export const bannerBackgroundBlurOptions = [
	{
		text: '0',
		value: ''
	},
	{
		text: '0.5',
		value: '0.5rem'
	},
	{
		text: '1',
		value: '1rem'
	},
	{
		text: '1.5',
		value: '1.5rem'
	},
	{
		text: '3',
		value: '3rem'
	}
];

export const bannerBackgroundGradientTypeOptions = [
	{ text: $t('Linear'), value: 'linear' },
	{ text: $t('Radial'), value: 'radial' }
];

export const bannerBackgroundTypes = {
	DEFAULT: 'default',
	LOGO: 'logo',
	IMAGE: 'image',
	COLOR: 'color',
	GRADIENT: 'gradient'
};

export const getSlideDefaults = (type = bannerSlideType.PRODUCT) => {
	return {
		isNew: true,
		type,
		background: '',
		textColor: '#fff',
		blur: '',
		productId: null,
		logo: '',
		name: $t('New slide name'),
		description: $t('New slide description'),
		linkText: '',
		linkUrl: '',
		maskColor: '#5289DC',
		maskOpacity: 0.5,
		backgroundOpacity: 0.5
	};
};

export const getProductData = product => {
	return {
		logo: _.get(product, 'previewImages[0].url'),
		name: product.name,
		description: product.shortDescription
	};
};

export const getSlideDataFromProduct = (product, isNew = true) => ({
	isNew,
	type: bannerSlideType.PRODUCT,
	productId: product.id,
	background: '',
	textColor: '#fff',
	blur: '',
	logo: _.get(product, 'previewImages[0].url'),
	name: product.name,
	description: product.shortDescription,
	linkText: product.availability === productAvailability.PreOrder ? $t('Pre-Order Now') : $t('Buy Now'),
	linkUrl: `/product/${product._id}`
});

export const prepareSlidesForSave = (slides, productsById) => {
	return slides.map(slide => {
		const result = { ...slide };

		if (slide.type === bannerSlideType.PRODUCT) {
			const product = productsById[slide.productId];

			const defaultLogo = _.get(product, 'previewImages[0].url');
			const defaultName = product.name;
			const defaultDescription = product.shortDescription;
			const defaultLinkText =
				product.availability === productAvailability.PreOrder ? $t('Pre-Order Now') : $t('Buy Now');
			const defaultLinkUrl = `/product/${product._id}`;

			if (result.logo === defaultLogo) delete result.logo;
			if (result.name === defaultName) delete result.name;
			if (result.description === defaultDescription) delete result.description;
			if (result.linkText === defaultLinkText) delete result.linkText;
			if (result.linkUrl === defaultLinkUrl) delete result.linkUrl;
		}

		return result;
	});
};

export const getSlideData = (slide, type, product) => {
	if (slide === null) {
		return getSlideDefaults(type);
	} else {
		const defaultLogo = _.get(product, 'previewImages[0].url');
		const defaultName = product.name;
		const defaultDescription = product.shortDescription;
		const defaultLinkText = product.availability === productAvailability.PreOrder ? $t('Pre-Order Now') : $t('Buy Now');
		const defaultLinkUrl = `/product/${product._id}`;

		const logo = slide.logo || defaultLogo;
		const name = slide.name || defaultName;
		const description = slide.description || defaultDescription;
		const linkText = slide.linkText || defaultLinkText;
		const linkUrl = slide.linkUrl || defaultLinkUrl;

		return {
			...slide,
			logo,
			name,
			description,
			linkText,
			linkUrl
		};
	}
};

export const prepareSlidesForForm = (slides, products) => {
	return slides
		.map(slide => {
			if (slide.type === bannerSlideType.PRODUCT) {
				const product = products[slide.productId];
				if (!product) return null;
				return getSlideData(slide, bannerSlideType.PRODUCT, product);
			}

			return slide;
		})
		.filter(el => el !== null);
};

export const templateFormats = {
	HTML: 'html',
	JSON: 'json'
};

export const templatesTypes = {
	EMAIL: 'email',
	PRODUCT: 'product'
};

export const templateFormatOptions = [
	{ title: $t('HTML'), value: 'html' },
	{ title: $t('JSON'), value: 'json' }
];

export const templateTypeOptions = [
	{ title: $t('Product'), value: 'product' },
	{ title: $t('Email'), value: 'email' }
];

export const pdfSampleModeTypes = {
	customerReview: 'customerReview',
	internalUse: 'internalUse',
	creatorsUse: 'creatorsUse'
};

export const siteDeployingStates = {
	new: 'new',
	enqueued: 'enqueued',
	building: 'building',
	processing: 'processing',
	uploading: 'uploading',
	ready: 'ready'
};

export const orderDetailsWorkingModes = {
	View: 'view',
	Edit: 'edit',
	Resubmit: 'resubmit'
};

export const EventTypes = {
	OrderReleased: 'OrderReleased',
	OrderReleaseFailed: 'OrderReleaseFailed',
	DonationProcessed: 'DonationProcessed',
	DonationProcessingFailed: 'DonationProcessingFailed'
};

export const taxBehaviourOptions = [
	{ text: $t('Inclusive'), value: 'inclusive' },
	{ text: $t('Exclusive'), value: 'exclusive' }
];

export const ordersQuery = {
	query: {
		$limit: 1,
		$sort: {
			_id: -1
		},
		$select: {
			orderId: 1,
			accountId: 1,
			status: 1,
			lineItems: 1,
			isTest: 1,
			shippingMethod: 1,
			shippingMethodId: 1,
			shipping: 1,
			billing: 1,
			createdAt: 1,
			updatedAt: 1,
			shipment: 1,
			lineItemIds: 1,
			productionOrderIds: 1,
			separatedProductionOrders: 1,
			logs: 1,
			isoCurrency: 1
		},
		$populate: [
			{
				path: 'lineItems',
				$populate: {
					path: 'product',
					$select: {
						name: 1,
						description: 1,
						expectedReleaseDate: 1,
						accountId: 1
					},
					$populate: [
						{
							path: 'creator'
						}
					]
				}
			},
			{ path: 'separatedProductionOrders' },
			{ path: 'shippingMethod' }
		],
		view: 'email'
	}
};

export const shippingMethodQuery = {
	query: {
		$select: {
			name: 1,
			description: 1,
			alias: 1,
			shippingRates: 1,
			createdAt: 1,
			updatedAt: 1
		}
	}
};

export const donationStatus = {
	Pending: 'pending',
	Paid: 'paid',
	Abandoned: 'abandoned',
	Refunded: 'refunded',
	Progressed: 'progressed'
};

export const donationReward = {
	Contributor: 'contributor',
	FirstEdition: 'firstEdition'
};

export const donationRewardLabels = {
	[donationReward.Contributor]: 'CONTRIBUTOR',
	[donationReward.FirstEdition]: 'FIRST EDITION'
};

export const donationStatusOptions = [
	{ text: $t('Paid'), value: donationStatus.Paid },
	{ text: $t('Refunded'), value: donationStatus.Refunded },
	{ text: $t('Progressed'), value: donationStatus.Progressed }
];
